<template>
  <div>
    <LoadingOverlay v-if="isFetching"></LoadingOverlay>
    <div v-else-if="!isError&&!isFetching">
      <InterviewEvaluationForm
        :isEdit="interviewFeedBack.length > 0"
        :feedBackInfo="feedBackInfo"
        :actionDetailInfo="actionDetailByID"
        :isExternalIEF="isExternalIEF"
        :externalUserInfo="externalUser"
      />
    </div>
    <Page404 v-else-if="isError" />
  </div>
</template>
<script>
import InterviewEvaluationForm from "@/containers/ActivityList/CandidateInterviewer/InterviewEvaluationForm";
import Page404 from "@/views/pages/Page404";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    InterviewEvaluationForm,
    Page404,
  },
  data() {
    return {
      isEdit: false,
      isFetching: false,
      isError: false,
    };
  },
  computed: {
    ...mapGetters([
      "interviewFeedBack",
      "actionDetailByID",
      "getUserId",
      "externalUser",
    ]),
    feedBackInfo() {
      return this.interviewFeedBack.length > 0 ? this.interviewFeedBack[0] : {};
    },
    isExternalIEF() {
      const { name, path } = this.$route;
      return name === "External IEF" && path.includes("/ief/");
    },
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "fetchInterviewFeedBack",
      "fetchActionDetailByID",
      "getExternalUserByHashId",
      "fetchActionDetailByHashId",
    ]),
  },
  mounted() {
    this.scrollTop();
    const {
      params: {
        job_id,
        candidate_uid,
        action_detail_id,
        ex_hash_id,
        action_hash_id,
      },
      query,
    } = this.$route;
    let appendAction = [];
    if (this.isExternalIEF) {
      appendAction = [
        ...appendAction,
        this.getExternalUserByHashId(ex_hash_id),
      ];
      appendAction = [
        ...appendAction,
        this.fetchActionDetailByHashId(action_hash_id),
      ];
    } else if (job_id && candidate_uid && action_detail_id) {
      let payload = {
        job_id,
        candidate_uid,
        action_detail_ids__overlap: `{${action_detail_id}}`,
        all_rows: true,
        created_by: this.getUserId,
      };
      if (query && query.previous_action_detail_id) {
        const { previous_action_detail_id } = query;
        payload = {
          ...payload,
          action_detail_ids__overlap: `{${action_detail_id},${previous_action_detail_id}}`,
        };
      }
      appendAction = [...appendAction, this.fetchInterviewFeedBack(payload)];
    }
    this.isFetching = true;
    Promise.all(appendAction).then(async (res) => {
      this.isError = res.some(({ isAxiosError }) => isAxiosError);
      if (res && !this.isError) {
        if (this.isExternalIEF) {
          const { action_detail_id, job_id, candidate_uid } =
            this.actionDetailByID;
          const { external_user_id } = this.externalUser;
          const payload = {
            created_by_external: external_user_id,
            job_id,
            candidate_uid,
            action_detail_ids__overlap: `{${action_detail_id}}`,
          };
          await this.fetchInterviewFeedBack(payload);
        }
        if (!this.interviewFeedBack.length && !this.isExternalIEF) {
          await this.fetchActionDetailByID({
            action_detail_id,
            payload: { job_id, candidate_uid },
          });
        }
      }
      this.isFetching = false;
    });
  },
};
</script>