<template>
  <div class="container-md col-lg-8 col-md-8 interview-feedback">
    <h5 class="text-primary">
      {{ isEdit ? "Edit" : "Create" }} Interview Evaluation Form<small
        v-if="uploadData.completed"
      >
        - Completed</small
      >
    </h5>
    <LoadingOverlay v-if="isFetching"></LoadingOverlay>
    <ValidationObserver ref="interviewEvaluationForm" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        name="interviewEvaluationForm"
      >
        <CCard>
          <CCardBody>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Candidate Name</label>
                  <CCol md="12">
                    <TextInput
                      name="full_name"
                      :value="candidateInfo.full_name"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Candidate Type</label>
                  <CCol md="12">
                    <Select
                      name="candidate_type"
                      :value="candidateInfo.candidate_type"
                      :disabled="true"
                      :clearable="false"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Current Employer</label>
                  <CCol md="12">
                    <TextInput
                      name="current_employer"
                      :value="uploadData.current_employer"
                      @input="handleInput"
                      tabindex="1"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label
                    class="col-lg-12 col-md-12"
                    v-if="candidateInfo.notice_period_days"
                    >Notice Period ({{ candidateInfo.notice_period_days }} day{{
                      candidateInfo.notice_period_days > 1 ? "s" : ""
                    }})</label
                  >
                  <label class="col-lg-12 col-md-12" v-else
                    >Notice Period (in days)</label
                  >
                  <CCol md="12">
                    <v-slider
                      name="notice_period_days"
                      :min="0"
                      :max="90"
                      :value="candidateInfo.notice_period_days"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Current Salary</label>
                  <CCol md="12">
                    <CRow>
                      <CCol class="pr-0" style="max-width: 70%">
                        <TextInput
                          name="current_salary"
                          :value="candidateInfo.current_salary"
                          :disabled="true"
                        />
                      </CCol>
                      <CCol class="p-0 ml-1" style="max-width: 25%">
                        <TextInput
                          name="current_salary_currency"
                          :value="candidateInfo.current_salary_currency"
                          :disabled="true"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Expected Salary</label>
                  <CCol md="12">
                    <CRow>
                      <CCol class="pr-0" style="max-width: 30%">
                        <TextInput
                          name="salary_expectation_min"
                          :value="candidateInfo.salary_expectation_min"
                          :disabled="true"
                        />
                      </CCol>
                      _
                      <CCol class="p-0" style="max-width: 30%">
                        <TextInput
                          name="salary_expectation_max"
                          :value="candidateInfo.salary_expectation_max"
                          :disabled="true"
                        />
                      </CCol>
                      <CCol class="ml-1 p-0" style="max-width: 25%">
                        <TextInput
                          name="salary_expectation_currency"
                          :value="candidateInfo.salary_expectation_currency"
                          :disabled="true"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <CCol style="max-width: 20%">
                    <CRow>
                      <label class="col-lg-12 col-md-12">Age</label>
                      <CCol md="12">
                        <TextInput
                          name="age"
                          :value="candidateInfo.age"
                          :disabled="true"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol style="max-width: 80%">
                    <CRow>
                      <label class="col-lg-12 col-md-12">Nationality</label>
                      <CCol md="12">
                        <Select
                          name="nationality_names"
                          :value="candidateInfo.nationality_names"
                          :disabled="true"
                          :multiple="true"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="3">
                <CRow>
                  <label class="col-lg-12 col-md-12">Marital Status</label>
                  <CCol md="12">
                    <RadioButton
                      name="marital_status"
                      :value="candidateInfo.marital_status"
                      :options="(options && options['marital_status']) || []"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="3" v-if="candidateInfo.marital_status === 'Married'">
                <CRow>
                  <label class="col-lg-12 col-md-12">Children</label>
                  <CCol md="12">
                    <TextInput
                      name="dependents"
                      :value="candidateInfo.dependents"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12"
                    >Languages Written/Spoken</label
                  >
                  <CCol md="12">
                    <Select
                      name="candidate_languages"
                      :value="candidateInfo.candidate_languages"
                      :clearable="false"
                      :multiple="true"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Comments</label>
                  <CCol md="12">
                    <TextareaInput
                      name="candidate_tab_comments"
                      :value="uploadData.candidate_tab_comments"
                      @input="handleInput"
                      tabindex="2"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mt-2">
          <CCardBody>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Interviewer's</label>
                  <CCol md="12">
                    <Select
                      name="interviewer"
                      :value="interviewInfo.interviewer"
                      :multiple="true"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12"
                    >Interviewee's Specialty</label
                  >
                  <CCol md="12">
                    <Select
                      name="candidate_specialities"
                      :value="candidateInfo.candidate_specialities"
                      :multiple="true"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Date/Time</label>
                  <CCol md="12">
                    <TextInput
                      name="datetime"
                      :value="interviewInfo.datetime"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Venue</label>
                  <CCol md="12">
                    <TextInput
                      name="venue"
                      :value="uploadData.venue"
                      @input="handleInput"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Interview Method</label>
                  <CCol md="12">
                    <TextInput
                      name="interview_method"
                      :value="uploadData.interview_method"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12"
                    >Candidate's Current Location</label
                  >
                  <CCol md="12">
                    <TextInput
                      name="current_country"
                      :value="candidateInfo.current_country"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Licensure Status</label>
                  <CCol md="12">
                    <TextInput
                      name="licenses"
                      :value="candidateInfo.licenses"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Source</label>
                  <CCol md="12">
                    <TextInput
                      name="source"
                      :value="uploadData.source"
                      @input="handleInput"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Comments</label>
                  <CCol md="12">
                    <TextareaInput
                      name="interview_tab_comments"
                      :value="uploadData.interview_tab_comments"
                      @input="handleInput"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <!-- <CRow class="mb-2">
              <CCol md="4"> -->
            <div class="d-flex align-items-center">
              <h6 class="text-primary">
                Competency
                <CIcon
                  name="cil-info"
                  class="hinticon-multi-select mx-1"
                  v-c-tooltip="
                    '100% - Exceeds Expectations | 75% - Meets Expectations | 50% - Satisfactory | 0% - Does not meet Expectations'
                  "
                />
              </h6>
            </div>
            <!-- </CCol>
              <CCol md="8">
                <div class="ml-auto mr-0 text-right fs-12">
                  <span class="text-nowrap"
                    ><span class="text-primary">100%</span> - Exceeds
                    Expectations</span
                  >
                  <span class="mx-1">|</span>
                  <span class="text-nowrap mr-1"
                    ><span class="text-primary">75%</span> - Meets
                    Expectations</span
                  ><span class="mx-1">|</span>
                  <span class="text-nowrap mr-1"
                    ><span class="text-primary">50%</span> - Satisfactory</span
                  ><span class="mx-1">|</span>
                  <span class="text-nowrap"
                    ><span class="text-primary">0%</span> - Does not meet
                    Expectations</span
                  >
                </div>
              </CCol>
            </CRow> -->
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Relevant Work Experience</label>
                  <CCol md="12">
                    <RadioButton
                      name="relevant_work_exp"
                      :value="uploadData.relevant_work_exp"
                      :options="
                        options && options['competency']
                          ? options['competency']
                          : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12"
                    >Knowledge of specific job skills</label
                  >
                  <CCol md="12">
                    <RadioButton
                      name="knowledge_spec_job_skills"
                      :value="uploadData.knowledge_spec_job_skills"
                      :options="
                        options && options['competency']
                          ? options['competency']
                          : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Communications skills</label>
                  <CCol md="12">
                    <RadioButton
                      name="communications_skills"
                      :value="uploadData.communications_skills"
                      :options="
                        options && options['competency']
                          ? options['competency']
                          : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Personality</label>
                  <CCol md="12">
                    <RadioButton
                      name="personality"
                      :value="uploadData.personality"
                      :options="
                        options && options['competency']
                          ? options['competency']
                          : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Interpersonal skills</label>
                  <CCol md="12">
                    <RadioButton
                      name="interpersonal_skills"
                      :value="uploadData.interpersonal_skills"
                      :options="
                        options && options['competency']
                          ? options['competency']
                          : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Appearance</label>
                  <CCol md="12">
                    <RadioButton
                      name="appearance"
                      :value="uploadData.appearance"
                      :options="
                        options && options['competency']
                          ? options['competency']
                          : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Overall Rating</label>
                  <CCol md="12">
                    <Rating
                      name="overall_rating"
                      :value="uploadData.overall_rating"
                      :isNumberRating="true"
                      @change="handleChangeRating"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12"
                    >All competencies weighted equally</label
                  >
                  <CCol md="12">
                    <TextInput
                      name="avgWeight"
                      :value="avgWeight"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Comments</label>
                  <CCol md="12">
                    <TextareaInput
                      name="competency_tab_comments"
                      :value="uploadData.competency_tab_comments"
                      @input="handleInput"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mt-2">
          <CCardBody>
            <h6 class="text-primary">Interviewer's Comments</h6>
            <p>Provide comments based on evaluation.</p>
            <CRow class="md-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Strengths</label>
                  <CCol md="12">
                    <TextareaInput
                      name="strengths"
                      :value="uploadData.strengths"
                      @input="handleInput"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Weakness</label>
                  <CCol md="12">
                    <TextareaInput
                      name="weakness"
                      :value="uploadData.weakness"
                      @input="handleInput"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="md-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Comments</label>
                  <CCol md="12">
                    <TextareaInput
                      name="comments"
                      :value="uploadData.comments"
                      @input="handleInput"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <h6 class="text-primary">Final Decision</h6>
            <CRow class="md-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Recommended for hire</label>
                  <CCol md="12">
                    <RadioButton
                      name="recommend_for_hire"
                      :value="uploadData.recommend_for_hire"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Hold/Keep for Future</label>
                  <CCol md="12">
                    <RadioButton
                      name="hold_for_future"
                      :value="uploadData.hold_for_future"
                      :options="
                        options && options['booleanNull']
                          ? options['booleanNull']
                          : []
                      "
                      @change="handleRadio"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mt-2">
          <CCardBody>
            <h6 class="text-primary">Interviewer Details</h6>
            <CRow class="mb-2">
              <CCol md="6" v-if="isExternalIEF">
                <CRow>
                  <label class="col-lg-12">First Name</label>
                  <CCol md="12">
                    <TextInput
                      name="first_name"
                      :value="externalUserData.first_name"
                      @input="handleInputExternal"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isExternalIEF">
                <CRow>
                  <label class="col-lg-12">Last Name</label>
                  <CCol md="12">
                    <TextInput
                      name="surname"
                      :value="externalUserData.surname"
                      @input="handleInputExternal"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isExternalIEF">
                <CRow>
                  <label class="col-lg-12">Organisation Name</label>
                  <CCol md="12">
                    <TextInput
                      name="organisation_name"
                      :value="externalUserData.organisation_name"
                      @input="handleInputExternal"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isExternalIEF">
                <CRow>
                  <label class="col-lg-12">Role</label>
                  <CCol md="12">
                    <TextInput
                      name="role"
                      :value="externalUserData.role"
                      @input="handleInputExternal"
                      :disabled="uploadData.completed"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6" v-if="!isExternalIEF">
                <CRow>
                  <label class="col-lg-12">Name</label>
                  <CCol md="12">
                    <TextInput
                      name="username"
                      :value="getUserName"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12">Date</label>
                  <CCol md="12">
                    <TextInput
                      name="date"
                      :value="currentDate"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CRow style="padding-bottom: 79px !important">
          <CCol sm="12" md="3" lg="3">
            <CButton
              type="button"
              name="cancel"
              color="primary"
              variant="outline"
              class="w-100"
              @click="navToActionDetail"
            >
              Cancel
            </CButton>
          </CCol>
          <CCol sm="12" lg="0" md="3"> </CCol>
          <CCol sm="12" lg="3" md="3">
            <CButton
              name="save"
              type="button"
              color="primary"
              class="w-100"
              @click="onSubmit(false)"
              :disabled="isSubmitted || uploadData.completed"
            >
              Save as Draft
              <CSpinner
                v-if="isSubmitted"
                class="spinner-border-sm text-white m-1"
              />
            </CButton>
          </CCol>
          <CCol sm="12" lg="3" md="3">
            <CButton
              name="save-submit"
              type="button"
              color="primary"
              @click="onSubmit(true)"
              :disabled="isSubmitted || uploadData.completed"
              class="w-100"
            >
              Save & Submit
              <CSpinner
                v-if="isSubmitted"
                class="spinner-border-sm text-white m-1"
              />
            </CButton>
          </CCol>
        </CRow>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import Rating from "@/components/reusable/Fields/Rating";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";

import m from "moment";
import Vue from "vue";
import { isEmptyObjectCheck, isObject } from "../../../helpers/helper";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    feedBackInfo: {
      type: Object,
      default: {},
    },
    actionDetailInfo: {
      type: Object,
      default: {},
    },
    isExternalIEF: {
      type: Boolean,
      default: false,
    },
    externalUserInfo: {
      type: Object,
      default: {},
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    PhoneInputwithCountry,
    Rating,
  },
  data() {
    return {
      candidateInfo: {},
      interviewInfo: {},
      uploadData: {
        action_detail_ids: [],
        interviewer_ids: [],
      },
      datetimeEmpty: null,
      date: new Date().toString(),
      isFetching: false,
      currentDate: m().format("DD-MM-YYYY"),
      isSubmitted: false,
      externalUserData: {},
      externalPayload: {},
    };
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "actionDetailByID",
      "getUserName",
      "getCustomerId",
      "getOrgIDFromAccessToken",
    ]),
    options() {
      return {
        competency: [
          { code: 100, label: "100%", info: "Exceeds Expectations" },
          { code: 75, label: "75%", info: "Meets Expectations" },
          { code: 50, label: "50%", info: "Satisfactory" },
          { code: 0, label: "0%", info: "Does not meet expectations" },
        ],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        marital_status: [
          { id: "Married", label: "Married" },
          { id: "Single", label: "Single" },
        ],
        booleanNull: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
          { code: null, label: "N/A" },
        ],
      };
    },
    avgWeight() {
      const data = this.uploadData;
      const relevant_work_exp = data?.relevant_work_exp || 0;
      const communications_skills = data?.communications_skills || 0;
      const interpersonal_skills = data?.interpersonal_skills || 0;
      const knowledge_spec_job_skills = data?.knowledge_spec_job_skills || 0;
      const personality = data?.personality || 0;
      const appearance = data?.appearance || 0;
      return (
        (
          (relevant_work_exp +
            knowledge_spec_job_skills +
            communications_skills +
            personality +
            appearance +
            interpersonal_skills) /
          6
        ).toFixed(2) || null
      );
    },
    actionDetail() {
      if (this.isEdit) {
        const ids = this.feedBackInfo.interview_action_details.map(
          ({ action_detail_id }) => action_detail_id
        );
        const maxId = Math.max(...ids);
        const index = ids.indexOf(maxId);
        return this.feedBackInfo.interview_action_details[index];
      }
      return this.actionDetailInfo;
    },
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "fetchActionDetailByID",
      "createInterviewFeedBack",
      "updateInterviewFeedBack",
      "updateExternalUserByHash",
      "updateIEFByExternal",
      "createIEFByExternal",
    ]),
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
    },
    handleInputExternal(name, value) {
      Vue.set(this.externalUserData, name, value);
      this.externalPayload[name] = value;
    },
    handleRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code;
      }
      Vue.set(this.uploadData, name, val);
    },
    handleChangeRating(name, value) {
      Vue.set(this.uploadData, name, value);
    },
    async onSubmit(completed = false) {
      const { job_id, candidate_uid, customer_uid, organisation_id } =
        this.actionDetail;
      this.uploadData = {
        ...this.uploadData,
        completed,
        job_id,
        customer_uid: this.getCustomerId || customer_uid,
        organisation_id: this.getOrgIDFromAccessToken || organisation_id,
        candidate_uid,
        weighted_avg: this.avgWeight,
      };
      this.isSubmitted = true;
      let appentAction = [];
      let payload = {
        action_detail_ids: this.uploadData.action_detail_ids,
        interviewer_ids: this.actionDetail.assigned_to || [],
        venue: this.uploadData.venue,
        interview_method: this.uploadData.interview_method,
        current_employer: this.uploadData.current_employer,
        source: this.uploadData.source,
        relevant_work_exp: this.uploadData.relevant_work_exp,
        communications_skills: this.uploadData.communications_skills,
        interpersonal_skills: this.uploadData.interpersonal_skills,
        knowledge_spec_job_skills: this.uploadData.knowledge_spec_job_skills,
        personality: this.uploadData.personality,
        appearance: this.uploadData.appearance,
        weighted_avg: this.uploadData.weighted_avg,
        overall_rating: this.uploadData.overall_rating,
        strengths: this.uploadData.strengths,
        weakness: this.uploadData.weakness,
        comments: this.uploadData.comments,
        candidate_tab_comments: this.uploadData.candidate_tab_comments,
        interview_tab_comments: this.uploadData.interview_tab_comments,
        competency_tab_comments: this.uploadData.competency_tab_comments,
        recommend_for_hire: this.uploadData.recommend_for_hire,
        hold_for_future: this.uploadData.hold_for_future,
        completed: this.uploadData.completed,
        external_interviewer_ids: this.actionDetail?.external_interviewer_ids,
      };
      if (this.isExternalIEF) {
        const created_by_external = this.externalUserInfo?.external_user_id;
        payload = {
          ...payload,
          created_by_external,
        };
        if (
          isObject(this.externalPayload) &&
          !isEmptyObjectCheck(this.externalPayload)
        ) {
          const { hash_id } = this.externalUserInfo;
          appentAction = [
            ...appentAction,
            this.updateExternalUserByHash({
              hash_id,
              payload: this.externalPayload,
            }),
          ];
        }
      }
      if (this.isEdit) {
        const { feedback_id } = this.uploadData;
        if (this.isExternalIEF)
          appentAction = [
            ...appentAction,
            this.updateIEFByExternal({ feedback_id, payload }),
          ];
        else
          appentAction = [
            ...appentAction,
            this.updateInterviewFeedBack({ feedback_id, payload }),
          ];
      } else {
        payload = {
          ...payload,
          job_id,
          customer_uid: this.getCustomerId || customer_uid,
          organisation_id: this.getOrgIDFromAccessToken || organisation_id,
          candidate_uid,
        };
        if (this.isExternalIEF) {
          appentAction = [...appentAction, this.createIEFByExternal(payload)];
        } else
          appentAction = [
            ...appentAction,
            this.createInterviewFeedBack(payload),
          ];
      }
      Promise.all(appentAction).then((res) => {
        this.isSubmitted = false;
        if (res) {
          this.navToActionDetail();
        }
      });
    },
    navToActionDetail() {
      if (this.isExternalIEF) {
        this.$router.push(`/candidate-list`);
        return;
      }
      const { status_id, sub_status_id, job_id, candidate_uid } =
        this.actionDetail;
      if (status_id === 54 && sub_status_id === 57) {
        this.$router.push(
          `/candidate-interviewer-activity-review/job/${job_id}/candidate/${candidate_uid}`
        );
      } else {
        this.$router.push(
          `/candidate-interviewer-activity-interview/job/${job_id}/candidate/${candidate_uid}`
        );
      }
    },
    populateData() {
      this.candidateInfo = {};
      this.interviewInfo = {};
      const data = this.isEdit ? this.feedBackInfo : this.actionDetailByID;
      const { candidate } = data;
      if (candidate)
        this.candidateInfo = {
          full_name: `${candidate?.first_name} ${candidate?.surname}`,
          candidate_type: candidate?.candidate_type?.candidate_type,
          candidate_languages: candidate.candidate_languages.map(
            ({ language }) => language?.language
          ),
          notice_period_days: candidate?.notice_period_days,
          current_salary: candidate?.current_salary,
          current_salary_currency:
            candidate?.current_salary_currency?.currency_code,
          salary_expectation_min: candidate?.salary_expectation_min,
          salary_expectation_max: candidate?.salary_expectation_max,
          salary_expectation_currency:
            candidate?.salary_expectation_currency?.currency_code,
          age: candidate?.age,
          nationality_names: candidate?.nationality_names,
          marital_status: candidate?.marital_status,
          dependents: candidate?.dependents,
          candidate_specialities: candidate?.candidate_specialities.map(
            (v) => v?.speciality?.speciality
          ),
          current_country: candidate?.current_country?.country_name,
          licenses: candidate?.licenses?.length > 0 ? "Yes" : "No",
        };

      let action_detail = data;
      this.uploadData = {
        interview_method: action_detail?.interview_mode,
        hold_for_future: null,
      };
      // const {
      //   params: {
      //     job_id,
      //     candidate_uid,
      //     action_detail_id: router_action_detail_id,
      //   },
      // } = this.$route;
      const { action_detail_id } = this.actionDetail;
      const action_detail_id_int = parseInt(action_detail_id);
      if (this.isEdit) {
        const ids = data.interview_action_details.map(
          ({ action_detail_id }) => action_detail_id
        );
        const maxId = Math.max(...ids);
        const index = ids.indexOf(maxId);
        action_detail = data.interview_action_details[index];
        this.uploadData = {
          ...data,
        };
        if (!data.action_detail_ids.includes(action_detail_id_int)) {
          this.uploadData.action_detail_ids.push(action_detail_id_int);
        }
        if (!data.interviewer_ids.includes(this.getUserId)) {
          this.uploadData.interviewer_ids.push(this.getUserId);
        }
      } else {
        this.uploadData = {
          ...this.uploadData,
          action_detail_ids: [action_detail_id],
          interviewer_ids: [this.getUserId],
        };
      }
      this.interviewInfo = {
        interviewer: action_detail?.assigned_to_users_name,
        datetime: this.interviewTimeStr(
          action_detail?.selected_slot_date,
          action_detail?.selected_slot_start,
          action_detail?.selected_slot_end
        ),
      };
      if (this.isExternalIEF) {
        this.externalUserData = { ...this.externalUserInfo };
      }
    },
    interviewTimeStr(date, startTime, endTime) {
      if (date && startTime && endTime) {
        const _date = m(date).format("DD-MMM-YYYY");
        const _start = this.formatTime(startTime);
        const _end = this.formatTime(endTime);
        return `${_date}  ${_start} - ${_end}`;
      }
      return null;
    },
    formatTime(date) {
      if (date) {
        const dateObj = new Date();
        const dateSplit = date.split(":");
        dateObj.setHours(dateSplit[0], dateSplit[1], dateSplit[2]);
        return m(dateObj).format("hh:mm A");
      }
      return null;
    },
  },
  mounted() {
    if ("current_user_type" in localStorage && "active_tab" in localStorage) {
      const currentUserType = JSON.parse(localStorage.getItem("current_user_type"));
      const activeTab = parseInt(localStorage.getItem("active_tab"));
      this.$store.commit("SET_CURRENT_USER_TYPE", currentUserType);
      this.$store.commit("SET_ACTIVE_TAB", activeTab);
      this.$store.commit("SET_ACTIVE_SUB_TAB", activeTab);
    }
    this.isEdit;
    this.populateData();
  },
};
</script>
<style lang="scss" scoped>
.fs-12 {
  font-size: 12px !important;
}
</style>
